import styled, { css, createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { vars } from './theme';

export const global = css`
  /* @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,900&display=swap'); */

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  @media screen and (min-width: 800px) {
    html {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1500px) {
    html {
      font-size: 20px;
    }
  }

  body {
    font-family: ${vars.fonts.body};
    background-color: ${vars.colors.white};
    color: ${vars.colors.grey.darkest};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
    /* letter-spacing: -0.01em; */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${vars.colors.black};
    font-family: ${vars.fonts.headings};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: ${vars.spacing[2]};
    line-height: 1.1;
    letter-spacing: -0.02em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h1 {
    font-size: 2rem;
    @media screen and (min-width: ${vars.break.sm}) {
      font-size: 2.6rem;
    }
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1rem;
  }
  @media screen and (min-width: 800px) {
    h1 {
      /* font-size: 3rem; */
    }
    h2 {
      font-size: 2rem;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: ${vars.spacing[2]};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: ${vars.spacing[3]};
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  a {
    color: ${vars.colors.primary.mid};
    text-decoration: none;
  }
  a:hover {
    color: ${vars.colors.primary.dark};
  }

  abbr[title] {
    text-decoration: none;
  }

  form {
    width: 100%;
  }

  fieldset {
    border: 0;
    padding: 0;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='number'],
  input[type='date'],
  input[type='search'],
  select,
  textarea {
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
    border: 0;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border: 0;
    display: block;
    border-radius: ${vars.borderRadius[1]};
    transition: all 0.3s ease-in-out;
    border: 2px solid ${vars.colors.grey.light};
    background-color: ${vars.colors.white};
    padding: ${vars.spacing[1]};
    line-height: 1;
    box-shadow: 0 12px 16px -8px rgba(0, 0, 0, 0.05);

    &:focus,
    &:active {
      outline: 0;
      border-color: ${vars.colors.primary.mid};

      & + label {
        background-color: ${vars.colors.grey.lightest};
      }
    }
  }

  select {
    height: 50px;
  }

  textarea {
    resize: none;
  }

  img {
    display: block;
    max-width: 100%;
    /* width: 100%; */
    height: auto;
  }
`;
export default createGlobalStyle`${normalize} ${global}`;

export const Container = styled('div')`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || vars.break.xxl};
  margin-left: auto;
  margin-right: auto;
  ${({ flush }) =>
    !flush &&
    css`
    padding-left: 5vw;
    padding-right: 5vw;
      /* padding-left: ${vars.spacing[2]}; */
      /* padding-right: ${vars.spacing[2]}; */
      @media screen and (min-width: ${vars.break.md}) {
        /* padding-left: ${vars.spacing[3]}; */
        /* padding-right: ${vars.spacing[3]}; */
      }
    `}
`;

export const BgImg = styled('img').attrs(props => ({ loading: 'lazy' }))`
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  opacity: 1;
  transition: all 0.2s ease-in-out;
`;

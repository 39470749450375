import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { getCookie } from '../lib/auth';
import { CURRENT_USER_QUERY } from '../lib/graphql';

const useUser = props => {
  // NEW
  const { data, error, loading } = useQuery(CURRENT_USER_QUERY, {
    // pollInterval: 1500,
  });
  const user = data?.currentUser || null;
  // console.log(user);

  // OLD
  // const authToken = getCookie('authToken', process.browser);
  // const refreshToken = getCookie('refreshToken', process.browser);
  // const client = useApolloClient();
  // React.useEffect(() => {
  //   if (authToken || refreshToken) {
  //     fetchCurrentUser();
  //   }
  // }, [authToken, refreshToken]);
  // const fetchCurrentUser = async () => {
  //   console.log('fetchCurrentUser');
  //   const res = await client.query({ query: CURRENT_USER_QUERY, fetchPolicy: 'network-only' }).catch(err => console.log(err));
  //   if (res && res.data && res.data.currentUser) {
  //     setUser(res.data.currentUser);
  //   }
  // };

  return user;
};

export default useUser;

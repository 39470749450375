import React from 'react';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloLink, Observable } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import Cookies from 'js-cookie';
import fetch from 'isomorphic-unfetch';

import { unsetTokens, getCookie } from './auth';

const API_URL = NEXT_APP_API_URL;

let apolloClient = null;

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent, { ssr = true } = {}) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client = apolloClient || initApolloClient(apolloState);
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    if (displayName === 'App') {
      console.warn('This withApollo HOC only works with PageComponents.');
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx) => {
      const { AppTree } = ctx;

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      const apolloClient = (ctx.apolloClient = initApolloClient({}, { headers: ctx?.req?.headers }));

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/react-ssr');
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                }}
              />
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            console.error('Error while running `getDataFromTree`', error);
          }

          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          Head.rewind();
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClient.cache.extract();

      return {
        ...pageProps,
        apolloState,
      };
    };
  }

  return WithApollo;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
export function initApolloClient(initialState, options = {}) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, options);
  }

  return apolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
function createApolloClient(initialState = {}, options) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const authToken = getCookie('authToken', process.browser, options.headers);
  const refreshToken = getCookie('refreshToken', process.browser, options.headers);

  // improve loading protected data when no errors are thrown on server
  // if no authToken is present but refreshToken is found, refresh the authToken
  // caveat - only works on the next query!
  // would be better if we could throw an error from the server when accessing protected fields
  if (refreshToken && !authToken) {
    refreshAuthToken(refreshToken);
  }

  const httpLink = createHttpLink({
    uri: API_URL,
    fetch,
  });

  const authLink = setContext((_, { headers: oldHeaders }) => {
    return {
      headers: {
        ...oldHeaders,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        'X-JWT-Refresh': refreshToken ? `${refreshToken}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward, ...rest }) => {
    // const refreshToken = getCookie('refreshToken', process.browser, headers);
    if (graphQLErrors) {
      // graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      for (let err of graphQLErrors) {
        switch (err.category) {
          case 'user':
            // console.log('user error');
            if (refreshToken) {
              return refreshAuthAndRetry(refreshToken, operation, forward);
            }
        }
      }
    }
    if (networkError) {
      // console.log(`[Network error]: Message: ${networkError.message}, Status code: ${networkError.statusCode}`);
      if (networkError.statusCode === 403) {
        console.log('403 error');
        if (refreshToken) {
          return refreshAuthAndRetry(refreshToken, operation, forward);
        } else {
          // remove auth and retry
          unsetTokens();
          console.log('remove auth and retry');
          return forward(operation);
        }
        // unsetTokens();
      }
    }
  });

  const defaultOptions = {
    query: {
      // fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    watchQuery: {
      // fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutation: {
      errorPolicy: 'all',
    },
  };

  return new ApolloClient({
    connectToDevTools: typeof window !== 'undefined',
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache().restore(initialState),
    defaultOptions,
  });
}

//

// this will refresh the token and retry
// for client side nav, it will also update the cookie
const refreshAuthAndRetry = (refreshToken, operation, forward) => {
  return new Observable((observer) => {
    refreshAuthToken(refreshToken)
      .then((newToken) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            Authorization: newToken ? `Bearer ${newToken}` : '',
          },
        }));
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        // unsetTokens();
        // operation.setContext(({ headers = {} }) => ({
        //   headers: {
        //     ...headers,
        //     Authorization: null
        //   }
        // }));
        observer.error(error);
      });
  });
};

// Refresh auth token
const refreshAuthToken = async (refreshToken) => {
  const res = await fetch(API_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `mutation {
            refreshJwtAuthToken( input: { clientMutationId: "refresh", jwtRefreshToken: "${refreshToken}" } ){
              authToken
            }
          }`,
    }),
  });
  const body = await res.json();
  const newToken = body?.data?.refreshJwtAuthToken?.authToken;
  if (newToken) {
    Cookies.set('authToken', newToken);
    return newToken;
  } else {
    unsetTokens();
    return null;
  }
};

import gql from 'graphql-tag';

// MUTATIONS

export const LOG_IN_MUTATION = gql`
  mutation LOG_IN($input: LoginInput!) {
    login(input: $input) {
      authToken
      refreshToken
      user {
        id
        name
      }
    }
  }
`;

// jwtRefreshToken: String!
// clientMutationId: String!
export const REFRESH_JWT_AUTH_TOKEN_MUTATION = gql`
  mutation REFRESH_JWT_AUTH_TOKEN($input: RefreshJwtAuthTokenInput!) {
    refreshJwtAuthToken(input: $input) {
      authToken
    }
  }
`;

export const LOG_OUT_MUTATION = gql`
  mutation LOG_OUT {
    logOut {
      message
    }
  }
`;

export const REGISTER_USER_MUTATION = gql`
  mutation REGISTER_USER($input: RegisterUserInput!) {
    registerUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation SEND_PASSWORD_RESET_EMAIL($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation PASSWORD_RESET($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      clientMutationId
    }
  }
`;

export const UPDATE_USER_WATCHED_MUTATION = gql`
  mutation UPDATE_USER_WATCHED($input: UpdateUserWatchedInput!) {
    updateUserWatched(input: $input) {
      clientMutationId
      user {
        id
      }
    }
  }
`;

// QUERIES

// SITE OPTIONS

export const OPTIONS_QUERY = gql`
  query OPTIONS {
    options {
      liveChatUrl
    }
  }
`;

// USER

export const USER_QUERY = gql`
  query USER($userId: ID!) {
    user(id: $userId) {
      name
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER {
    currentUser {
      id
      userId
      username
      name
      email
      capabilities
      hasActiveSubscription
      hasTrialSubscription
      hasHadTrial
      subscription {
        providerId
        status: subscriptionStatus
        # endDate
        # cancelAtPeriodEnd
      }
      subscriptions {
        providerId
        status: subscriptionStatus
        source
        name
        startDate
        endDate
        nextBillingDate
        price
        currency
        canBeCancelled
        canBeReactivated
        paddleUpdateUrl
        paddleCancelUrl
      }
      watched {
        lesson
        video
        progress
        completed
        updatedAt
      }
    }
  }
`;

export const CURRENT_USER_INVOICES_QUERY = gql`
  query CURRENT_USER_INVOICES {
    currentUser {
      id
      invoices {
        id
        status: invoiceStatus
        amount
        url
        created
      }
    }
  }
`;

// export const CURRENT_USER_SUBSCRIPTION_QUERY = gql`
//   query CURRENT_USER_SUBSCRIPTION {
//     currentUser {
//       id
//       subscription {
//         id
//         status: subscriptionStatus
//         endDate
//       }
//     }
//   }
// `;

// NO LONGER USED - was able to find single subscription via ID
// export const CURRENT_USER_SUBSCRIPTION_QUERY = gql`
//   query CURRENT_USER_SUBSCRIPTION {
//     currentSubscription {
//       status: subscriptionStatus
//       endDate
//       # id
//       # user_id
//     }
//   }
// `;

// LESSONS

export const LESSON_BY_SLUG_QUERY = gql`
  query LESSON_BY_SLUG($slug: String!) {
    lesson: lessonBy(uri: $slug) {
      id
      lessonId
      slug
      title
      description: content
      duration
      videoCount
      image: featuredImage {
        srcSet
        src: sourceUrl
      }
      subjects {
        nodes {
          slug
          name
        }
      }
      videos {
        title
        slug
        url
        isPublic
        duration
        durationSecs
        lessonId
      }
      files {
        title
        url
        type
      }
      instructors {
        id
        title
        slug
        image: featuredImage {
          src: sourceUrl
        }
      }
      hasTab
      hasBackingTrack
    }
  }
`;

export const LESSONS_QUERY = gql`
  query LESSONS(
    $first: Int
    $after: String
    $where: RootQueryToLessonConnectionWhereArgs
  ) {
    lessons(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        lessonId
        slug
        title
        description: content
        duration
        videoCount
        image: featuredImage {
          srcSet
          src: sourceUrl
        }
        subjects {
          nodes {
            slug
            name
          }
        }
        videos {
          title
          slug
          duration
          durationSecs
          url
        }
        files {
          title
          url
          type
        }
        instructors {
          id
          title
          slug
          image: featuredImage {
            src: sourceUrl
            srcSet
          }
        }
        hasTab
        hasBackingTrack
        isCompleted
      }
    }
  }
`;

export const LESSONS_SEARCH_QUERY = gql`
  query LESSONS_SEARCH(
    $first: Int
    $after: String
    $search: String
    $subjects: [String]
    $hasTab: Boolean
    $hasTrack: Boolean
    $instructors: [String]
  ) {
    lessons(
      first: $first
      after: $after
      where: {
        search: $search
        subjects: $subjects
        hasTab: $hasTab
        hasTrack: $hasTrack
        instructors: $instructors
      }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        slug
        title
        description: content
        duration
        videoCount
        image: featuredImage {
          srcSet
        }
        subjects {
          nodes {
            slug
            name
          }
        }
        videos {
          title
          slug
          duration
        }
        files {
          title
          url
          type
        }
        instructors {
          id
          title
          slug
          image: featuredImage {
            src: sourceUrl
          }
        }
        hasTab
        hasBackingTrack
      }
    }
  }
`;

export const NEXT_LESSON_QUERY = gql`
  query NEXT_LESSON {
    nextLesson {
      id
      # slug
      title
      dateGmt
      description: content
      duration
      videoCount
      image: featuredImage {
        srcSet
      }
      subjects {
        nodes {
          slug
          name
        }
      }
    }
  }
`;

// MASTERCLASSES

export const MASTERCLASSES_QUERY = gql`
  query MASTERCLASSES(
    $first: Int
    $after: String
    $where: RootQueryToMasterclassConnectionWhereArgs
  ) {
    masterclasses(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        title
        description: content
        image: featuredImage {
          srcSet
        }
        url
        dateGmt
        isFuture
        isLive
      }
    }
  }
`;

export const LATEST_MASTERCLASS_QUERY = gql`
  query LATEST_MASTERCLASS {
    latestMasterclass {
      id
      title
      description: content
      image: featuredImage {
        srcSet
      }
      url
      dateGmt
      isFuture
      isLive
    }
  }
`;

// INSTRUCTORS

export const INSTRUCTOR_BY_SLUG_QUERY = gql`
  query INSTRUCTOR_BY_SLUG($slug: String!) {
    instructor: instructorBy(uri: $slug) {
      id
      slug
      title
      description: content
      image: featuredImage {
        srcSet
        src: sourceUrl
      }
    }
  }
`;

export const INSTRUCTORS_QUERY = gql`
  query INSTRUCTORS(
    $first: Int
    $after: String
    $where: RootQueryToInstructorConnectionWhereArgs
  ) {
    instructors(first: $first, after: $after, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        slug
        title
        description: content
        image: featuredImage {
          srcSet
          src: sourceUrl
        }
      }
    }
  }
`;

// SUBJECTS

export const SUBJECTS_QUERY = gql`
  query SUBJECTS {
    subjects(first: 99, where: { hideEmpty: true }) {
      nodes {
        slug
        name
      }
    }
  }
`;

// TRACKS

export const TRACKS_QUERY = gql`
  query TRACKS($first: Int, $after: String, $search: String) {
    tracks(
      first: $first
      after: $after
      where: { search: $search, orderby: { field: DATE, order: DESC } }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        title
        url
        bpm
        key
      }
    }
  }
`;

// STRIPE SUBSCRIPTIONS

export const STRIPE_SESSION_CREATE_MUTATION = gql`
  mutation STRIPE_SESSION_CREATE($input: CreateStripeSessionInput!) {
    createStripeSession(input: $input) {
      clientMutationId
      id
    }
  }
`;

export const STRIPE_SUBSCRIPTION_UPDATE_MUTATION = gql`
  mutation STRIPE_SUBSCRIPTION_UPDATE($input: UpdateStripeSubscriptionInput!) {
    updateStripeSubscription(input: $input) {
      clientMutationId
      id
    }
  }
`;

export const SUBSCRIPTION_CANCEL_MUTATION = gql`
  mutation SUBSCRIPTION_CANCEL($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      clientMutationId
      cancelled
    }
  }
`;

export const STRIPE_SUBSCRIPTION_UNCANCEL_MUTATION = gql`
  mutation STRIPE_SUBSCRIPTION_UNCANCEL(
    $input: UncancelStripeSubscriptionInput!
  ) {
    uncancelStripeSubscription(input: $input) {
      clientMutationId
      uncancelled
    }
  }
`;

// PADDLE SUBSCRIPTIONS

export const PADDLE_SESSION_CREATE_MUTATION = gql`
  mutation PADDLE_SESSION_CREATE($input: CreatePaddleSessionInput!) {
    createPaddleSession(input: $input) {
      clientMutationId
      url
    }
  }
`;

// BRAINTREE SUBSCRIPTIONS

export const BRAINTREE_CLIENT_TOKEN_CREATE_MUTATION = gql`
  mutation BRAINTREE_CLIENT_TOKEN_CREATE(
    $input: CreateBraintreeClientTokenInput!
  ) {
    createBraintreeClientToken(input: $input) {
      clientMutationId
      token
    }
  }
`;

export const BRAINTREE_SUBSCRIPTION_CREATE_MUTATION = gql`
  mutation BRAINTREE_SUBSCRIPTION_CREATE(
    $input: CreateBraintreeSubscriptionInput!
  ) {
    createBraintreeSubscription(input: $input) {
      clientMutationId
      id
    }
  }
`;

// Mailing list
export const MAILING_LIST_ADD_MUTATION = gql`
  mutation MAILING_LIST_ADD($input: AddToMailingListInput!) {
    addToMailingList(input: $input) {
      clientMutationId
      success
    }
  }
`;

// Contact form
export const CONTACT_FORM_SEND_MUTATION = gql`
  mutation CONTACT_FORM_SEND($input: SendContactFormInput!) {
    sendContactForm(input: $input) {
      clientMutationId
      success
    }
  }
`;

// ALL SUBSCRIPTIONS

export const ALL_SUBSCRIPTIONS_QUERY = gql`
  query ALL_SUBSCRIPTIONS {
    subscriptions: allSubscriptions {
      providerId
      status: subscriptionStatus
      source
      name
      startDate
      endDate
      nextBillingDate
      price
      currency
      canBeCancelled
      canBeReactivated
    }
  }
`;

// SUBSCRIPTIONS BY

export const SUBSCRIPTIONS_BY_QUERY = gql`
  query SUBSCRIPTIONS_BY($referrer: String!) {
    trialSubscriptions: subscriptionsBy(referrer: $referrer, status: "TRIAL") {
      providerId
      status: subscriptionStatus
      source
      name
      startDate
      endDate
      nextBillingDate
      price
      currency
      canBeCancelled
      canBeReactivated
    }
    activeSubscriptions: subscriptionsBy(
      referrer: $referrer
      status: "ACTIVE"
    ) {
      providerId
      status: subscriptionStatus
      source
      name
      startDate
      endDate
      nextBillingDate
      price
      currency
      canBeCancelled
      canBeReactivated
    }
  }
`;
